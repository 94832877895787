import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

import Faq from "../../components/Faq"
import PricingTable from "../../components/PricingTable"
import FreeDiscussion from "../../components/FreeDiscussion"
import Steps from "../../components/Steps"
import FeaturedCarousel from "../../components/FeaturedCarousel"
import InfoSection from "../../components/InfoSection"
import HeroSection from "../../components/HeroSection"
import { Guide } from "../../components/Guide"
import { DownloadBanner } from "../../components/DownloadBanner"
import AppDownloadBanner from "../../components/AppDownloadBanner"

const LettersOfAdministrationInPerth = ({ data, path }) => {
  const { informationSection, heroSection } = data.markdownRemark.frontmatter

  return (
    <Layout hasSubNav={true} navId="lostALovedOne">
      <SEO
        path={path}
        title="Letters of Administration in Sydney | Letters of Administration in Perth"
        description="Need help filing LETTERS OF ADMINISTRATION in PERTH or SYDNEY? Access assets of deceased estate. Our experienced lawyers will prepare your documents. Get started."
        image="/img/letters-of-admin.jpeg"
      />

      {/* Hero Section */}
      <HeroSection
        className="subHeader"
        id="letters-of-administration-hero"
        formButtonName="letters-of-administration-perth"
        bgImage={heroSection.bgImage}
        subTitle={heroSection.subTitle}
        buttonLinks={heroSection.buttonLinks}
      >
        <div className="d-flex flex-column mb-5">
          <h1 className="font-black">
            Get <span className="emphasize-text">Letters of</span>
            <span className="d-block">
              <span className="emphasize-text">Administration</span>
            </span>
            <span className="d-block">in Perth, WA</span>
          </h1>
        </div>
      </HeroSection>

      <section className="bg-light">
        <FeaturedCarousel />
      </section>

      {/* video  */}
      <section className="bg-white">
        <div className="container section-gap">
          <div className="info-card row p-4 p-sm-0 p-lg-0 custom-shadow overflow-hidden">
            <div className="info-image col-12 col-xl-6 px-0 py-xl-4 pl-xl-4">
              <iframe
                className="video-general-iframe"
                src="https://www.youtube.com/embed/FPUyDWKlV-A"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="info-text col-12 col-xl-6 px-0 px-sm-4 pt-5 py-sm-4 p-lg-5">
              <div className="info-text-inner d-flex flex-column justify-content-center">
                <h2 className="info-title mb-4 font-bold font-size-lg-5">
                  Applying For Letters of Administration In Australia
                </h2>
                <p>
                  Letters of Adminisration is the process where close family
                  members apply to the court for permission to deal with your
                  loved one’s estate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg-white">
        <Steps colCount={3} stepsName="lost-a-loved-one">
          <h2 className="font-bold text-center mb-5 mb-sm-7 d-block">
            What are the{" "}
            <span className="bg-accent px-3 text-white">steps?</span>{" "}
          </h2>
        </Steps>
      </section> */}

      <section className="bg-light">
        <InfoSection infoList={informationSection.list} />
      </section>

      <section className="bg-white">
        {/* <PricingTable tableName="letters-of-administration">
          <h2 className="text-center font-bold mb-5">
            <span className="d-block">Letters of Administration</span> in Perth,
            WA <span className="emphasize-text">Pricing</span>
          </h2>
          <h3 className="text-center">
            <span className="d-block d-md-inline-block mb-2 mb-md-0 mr-0 mr-md-2">
              Deal with a Deceased Estate in Perth, Western Australia.
            </span>
            <span className="d-block d-md-inline-block mb-2 mb-md-0 mr-0 mr-md-2">
              Save 80% on Legal Fees*.
            </span>
          </h3>
        </PricingTable> */}
        <AppDownloadBanner />
      </section>
      <Guide />
      <section className="bg-light">
        <FreeDiscussion name="letters-of-administration-perth" />
      </section>

      <section className="bg-white">
        <Faq name="letters-of-administration">
          <div className="col-12 mb-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              {/* Section Title */}
              <h2 className="mb-4 font-bold text-center">
                <span className="d-block d-md-inline-block mb-3 mb-md-0 mr-0 mr-md-3">
                  Frequently Asked{" "}
                </span>
                <span className="bg-accent px-2 text-white">Questions</span>
              </h2>
              {/* Section Subtitle */}
              <p className="font-semibold text-center">
                We’re here to help you navigate through the loss of a loved one
              </p>
            </div>
          </div>
        </Faq>
      </section>
      <DownloadBanner />
    </Layout>
  )
}

export default LettersOfAdministrationInPerth

export const pageQuery = graphql`
  query {
    markdownRemark(
      frontmatter: {
        templateKey: {
          eq: "lost-a-loved-one/letters-of-administration-perth-wa"
        }
      }
    ) {
      frontmatter {
        heroSection {
          bgImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          subTitle {
            content
          }
          buttonLinks {
            linkText
            linkTo
            linkStyle
            type
          }
        }
        informationSection {
          list {
            title
            details
            hasImage
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
